@use "sass:math";

@mixin reset-button {
  margin: 0;
  padding: 0;
  border: none;
  background: none;
}

@mixin reset-list {
  margin: 0;
  padding: 0;
  list-style: none;
  text-indent: 0;
}

@mixin blind {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  overflow: hidden;
  clip-path: polygon(0 0, 0 0, 0 0);
}

@function pxToRem($value) {
  @return math.div($value, 14) * 1rem;
}

@function ptr($value) {
  @return pxToRem($value);
}

@mixin pc-layout-inner-style {
  box-sizing: border-box;
  width: 100%;
  max-width: $pc-max-width;
  margin: 0 auto;
  padding: 0 pxToRem(80px);
}

@mixin stripe-image($x: 0, $y: 0) {
  background: url("//s3.marpple.co/files/u_1396787/2021/4/original/73432b5a7c3fdaa2e1202d56fe52c4a20dace2061.png")
    no-repeat;
  background-position: $x $y;
  background-size: 200px auto;
}

@mixin stripe-image-before($w, $h, $u, $s) {
  content: "";
  display: block;
  width: $w;
  height: $h;
  background: url($u) no-repeat;
  background-size: $s auto;
}

@mixin hide-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

@mixin limit-line($l) {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: $l;
  overflow: hidden;
}

@mixin grid-columns($n) {
  display: grid;
  grid-template-columns: repeat($n, minmax(0, 1fr));
}

@mixin grid-rows($n) {
  display: grid;
  grid-template-rows: repeat($n, minmax(0, 1fr));
}
