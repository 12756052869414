@import "../../../../../../../www/css/creator/style_mixins";
@import "./header.category";

// modules/MarppleShop/PlusShop/ProductList/F/Style/Pc/index.styl

//$ness_plus_product_list_min_height: 700px; // s('calc(100vh - #{$ness-default-header_height})');

@mixin data-show {
  &[data-show="false"] {
    display: none;
  }
}

.plus-shop-product-list-page {
  margin-bottom: ptr(120);
}

.plus-shop-product-list {
  position: relative;
  min-height: 10px;
  margin-top: 10px;
  margin-bottom: ptr(120);

  &__features {
    display: flex;
    align-items: end;
    margin-bottom: ptr(40);
  }

  &__filters {
    display: flex;
    flex-shrink: 0;
    justify-content: space-between;
  }

  &__product-filters {
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: end;
  }

  // FIXME
  &__dropdown .mshop-comp-dropdown__button,
  &__filter-all {
    display: flex;
    align-items: center;
    border-radius: ptr(26);
  }

  &__soldout-filter {
    margin-left: ptr(16);
  }

  &__sort-box {
    display: flex;
    flex-shrink: 0;
    margin-left: ptr(24);

    @include data-show;
  }

  &__filter-all {
    @include reset-button;
  }

  &__products {
    @include grid-columns(5);

    grid-gap: ptr(48) ptr(16);
  }

  &__products--empty {
    top: 50%;

    @include data-show;
  }

  &__dropdown .mshop-comp-dropdown__button {
    display: flex;
    align-items: center;
  }

  &__cate-lists {
    display: flex;
    box-sizing: border-box;
    width: 100%;
    column-gap: ptr(24);
  }

  &__cate-list {
    display: flex;
    flex-direction: column;

    @include reset-button;
    @include data-show;

    &[data-special_id="limited"],
    &[data-special_id="popular"] {
      .plus-shop-product-list__cate-list-name {
        display: none;
      }
    }
  }

  &__cate-list-desc {
    margin-bottom: ptr(8);
    color: $gray-80;
  }

  &__cate-list-title {
    display: flex;
    align-items: end;
    color: $black;
    gap: ptr(8);
  }

  &__cate-items {
    display: flex;
    box-sizing: border-box;
    width: 100%;
    margin-top: ptr(24);
    overflow: scroll;
    gap: ptr(24);

    @include hide-scrollbar;
  }

  &__cate-item {
    color: $gray-50;

    @include reset-button;
    @include data-show;

    &[data-selected="true"] {
      color: $black;
    }
  }
}

//  position: relative;
//  margin-top: 10px; // s('calc(#{$ness-default-header_height} + #{ptr(90)}');
//  //margin-bottom: ptr(120);
//  display: none;
//  min-height: 10px; //  s('calc(100vh - #{$margin-top})');
//  //@include container-name(ness-plus-product-list);
//  //@include container-type(inline-size);
//

//}

//.ness-plus-product-list {
//  .ness-plus-product-list__filters {
//    //margin-top: ptr(24);
//  }
//
//  &[data-type="recent"],
//  &[data-type="limit"],
//  &[data-type="popular"] {
//    .ness-plus-product-list__filters {
//      //margin-top: ptr(8);
//    }
//  }
//}

// 상품 width < 158px 이면 grid auto-fit으로 변경
// 1.7rem = ptr(24)
// 여기에 변수 넣으니까 동작 안해서 일단 이렇게
//@container ness-plus-product-list (max-width: calc(158px * 5 + 1.7rem * 4)) {
//  .ness-plus-product-list__products {
//    grid-template-columns: repeat(auto-fit, minmax(158px, 1fr));
//  }
//}
