.plus-shop__category-headers {
  display: flex;
  gap: ptr(32);
  padding: ptr(20) ptr(32);
}

.plus-shop__category-header {
  color: $gray-80;
  cursor: pointer;

  &:visited,
  &:link {
    color: $gray-80;
  }

  &[data-selected="true"] {
    color: $blue;
  }
}
